import React, { useState, useEffect, Fragment } from "react";
import styled from "styled-components";
import Header from "../components/Header";
import Banner from "../components/Banner";
import OverlayContent from "../components/Banner/OverlayContent";
import Service from "../components/Service";
import {
  serviceBannerText,
  weddingText,
  promText,
  nightOutText,
  corporateText,
  wineText,
  airportText,
  birthdayText,
  eventText,
} from "../constants";
import Footer from "../components/Footer";
import WeddingsImg from "../images/wedding.jpg";
import PromImg from "../images/prom.jpeg";
import NightOutImg from "../images/nightout.jpeg";
import BirthdayImg from "../images/birthday.jpeg";
import CasinoImg from "../images/casino.jpeg";
import AirportImg from "../images/airport.jpeg";
import CorporateImg from "../images/corporate.jpeg";
import WineImg from "../images/wine.jpeg";
import ServiceBannerImg from "../images/servicebanner.jpeg";
import scrollTo from "gatsby-plugin-smoothscroll";
import SEOHelmet from "../components/SEOHelmet";

const ServicesPage = ({ location }) => {
  useEffect(() => {
    if (location?.state?.service) {
      scrollTo(`#${location?.state?.service}`);
    } else if (location?.state?.scrollToTop) {
      scrollTo("#top");
    }
  }, []);

  const subTitle = () => <div>{serviceBannerText}</div>;

  return (
    <ServiceContainer id="top">
      <SEOHelmet
        title="Memories Limousine | Services"
        description="Information about booking memories limousine services in durham region"
      />
      <Header />
      <Banner img={ServiceBannerImg} showOverlay>
        <OverlayContent title={"Our Services"} subTitle={subTitle} />
      </Banner>
      <ContentContainer>
        <Row id="wedding">
          <Service
            title={"Wedding"}
            text={weddingText}
            img={WeddingsImg}
            alt="Wedding party"
          />
          <div id="prom" />
          <Service
            title={"Prom and Graduation"}
            text={promText}
            img={PromImg}
            alt="Prom party"
          />
        </Row>
        <Row id="nightOut">
          <Service
            title={"Night Out"}
            text={nightOutText}
            img={NightOutImg}
            alt="People partying during a night out"
          />
          <div id="birthday" />
          <Service
            title={"Birthday"}
            text={birthdayText}
            img={BirthdayImg}
            alt="People celebrating birthday"
          />
        </Row>
        <Row id="casino">
          <Service
            title={"Events and Concerts"}
            text={eventText}
            img={CasinoImg}
            alt="Casino chips on table"
          />
          <div id="airport" />
          <Service
            title={"Airport Dropoff"}
            text={airportText}
            img={AirportImg}
            alt="Suitcase dropped off at airport"
          />
        </Row>
        <Row id="corporate">
          <Service
            title={"Corporate Function"}
            text={corporateText}
            img={CorporateImg}
            alt="Professional Event"
          />
          <div id="wine" />
          <Service
            title={"Wine Tour"}
            text={wineText}
            img={WineImg}
            alt="Wine glass on table"
          />
        </Row>
      </ContentContainer>
      <Footer />
    </ServiceContainer>
  );
};

/*--------------Styling-----------------*/

const ServiceContainer = styled.div`
  background-color: #fff;
`;

const Title = styled.div`
  font-size: 32px;
  font-weight: 700;
  color: black;
  text-align: center;
`;

const ContentContainer = styled.div`
  max-width: 1400px;
  margin: auto;
  margin-top: 80px;
  margin-bottom: 80px;
  padding: 0 20px;
  @media (max-width: 1200px) {
    margin-top: 20px;
  }
`;

const Row = styled.div`
  display: flex;
  justify-content: space-around;
  margin-top: 100px;
  @media (max-width: 1200px) {
    /* screen width is less than 768px (medium) */
    flex-direction: column;
    align-items: center;
    margin-top: 0px;
  }
`;
export default ServicesPage;
