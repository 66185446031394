import * as React from "react";
import styled from "styled-components";
import "../../styles/global.css";
import {ButtonLink} from '../ButtonLink';


const Service = (props: {
    title: string, text: string, img: any, alt: string
}) => {

    const {title,text, img, alt} = props;

  return (
    <ServiceContainer>
        <Image src = {img} alt = {alt}/>
        <Title> {title} </Title>
        <Text> {text} </Text>
        <StyledButtonLink to={'/contact'} state={{ service: title }}> Get a Quote  </StyledButtonLink>
    </ServiceContainer>
  );
};

/*--------------Styling-----------------*/

const ServiceContainer = styled.div`
    max-width : 600px;
    margin-top:60px;
    text-align:left;
    @media (max-width: 1200px) {
      /* screen width is less than 768px (medium) */
      text-align:center;
      margin-left: 0px;
    }
`

const Image = styled.img`
  width: 100%;
  max-height: 350px;
`

const Title = styled.h2`
font-weight: 600;
color: 4f4f4f;
margin-left: 20px;
font-size: 36px;
margin-bottom: 10px;
`;

const Text = styled.div`
font-weight: 300;
line-height: 37px;
margin: auto;
font-size: 18px;
padding: 0px 20px;
color: black;
`

const StyledButtonLink = styled(ButtonLink)`
margin:20px;
@media (max-width: 1200px) {
  /* screen width is less than 768px (medium) */
  margin:auto;
  margin-top:20px;
}
`
export default Service;
